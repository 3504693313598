.footerPadding {
  padding: 5px;
  color: white;
  background-color: rgb(238, 237, 237);
  height: auto;
}

.border-top2{
  border-top: 1px solid rgb(189, 189, 189);
}

.imgPublicaEnter{
  width: 143px;
  height: 40px;
  margin-top: 10px;
  object-fit: fill;
  
}

.imgPublicadev{
  width: 115px;
  height: 58px;
  margin-top: 0px;
  object-fit: cover;

}

.last-line{
  font-size: 13px;
}

.subclickbtn{
  cursor: pointer;
}

.modal-img{
  width: 60%;
 border-radius: 10px
}

.followPadding {
  padding-right: 3rem;
  padding-left: 3rem;
  margin-top: 50px;
}

.SubBtn {
  margin: auto !important;
}


.textdata p{
  font-size: 14px;
}
.FootLogo {
  
  width: 100%;
  max-width: 150px;

 
  height: auto;
 

}




.dd{
  border-bottom: 1px solid white;
}
.imgPublica {
  width: 100%;
  margin-top: 10px;
 
}

.imgPublicaCMO {
  width: 100%;
  margin-top: 10px;
  width: 90px;
  height: 35px;

}

.imgET {
  width: 100%;
}
.facebookImg {
  border: 2px solid white;
  margin: auto;
  text-align: center;
  padding: 5px;
  border-radius: 7px;
  width: 14%;
}




  .facebookImg{
    background-color: black;
    margin: auto;
    text-align: center;
    padding: 6px;
    border-radius: 7px;
}

.followPadding{
  padding-right: 3rem;
  padding-left: 3rem;
  margin-top: 50px;
}

.iIMG{
  width: 18px;
  height: auto;
}

.fIMG{
width: 11px;
height: auto;
}

.xIMG{
width: 56%;
border-radius: 6px;
height: auto;
}



.otherPublic {
width: 50% !important;
text-align: center;
margin: auto;
gap: 20px;
display: flex;
}


.pRed{
  color: #309b65;
}


.mainDiv{
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
}

.mainDiv div{
  width: 80%;
  /* height: 40px; */
  padding: 7px 5px 7px 5px;
  border-radius: 5px;
}


.ximage{
  width: 40%;
}



@media (max-width: 576px) {
  .spaceincontent {
    margin-top: 10px !important;
  }

  .spaceincontentbottm {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 768px) {


  .footerPadding{
    height: auto;
  }

  .otherPublic{
    display: grid !important;
  grid-template-columns: repeat(2, 1fr);
  }


  .mainDiv{
    
    width: 50%;
     
  }
  
  .mainDiv div{
   
    width: 100% !important;
    
  }

  .ximage{
    width: 55%;
  }
  
  
}

@media (min-width: 767px) and (max-width: 992px)  {

  .mainDiv{
    
    width: 100%;
     
  }
  
  .mainDiv div{
   
    width: 100% !important;
    
  }

  .ximage{
    width: 80%;
  }

}