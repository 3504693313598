.topicImg {
  height: auto;
  width: 100%;
  border-radius: 15px;
}



.newTOC{
  position: sticky;
  margin-left: auto ;
  top:75px;

 
}

.max-toc{
  width: 100%;
overflow-x: unset !important;
  padding: 0 30px;
  max-width: 1440px;
}

.newTOC{
  position: sticky;
  margin-left: auto ;
  top:75px;

 
}
.content {
  white-space: normal;
  font-size: 14px;
}

.content img {
  width: 100%;
  object-fit: contain;
}
.video-responsive{ overflow:hidden; padding-bottom:56.25%; position:relative; height:0; } 
.video-responsive iframe{ left:0; top:0; height:100%; width:100%; position:absolute; }

.content p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  line-height: 1.7;
  text-align: left !important;
  font-size: 14px;
  font-weight: 500;
}

.content h2{
  font-size: 30px;
}

.content h3{
  font-size: 24px;
}


.content a{
  color: #309b65;
}

/* .content h1, h2, h3, h4, h5, h6 {
margin-top: 20px !important;
margin-bottom: 20px !important;
} */

.backLine{
  text-decoration: none !important;
}

.content .td-post-content {
  margin-top: 21px;
  padding-bottom: 5px;
}

.content .tagdiv-type {
  font-size: 15px;
  line-height: 1.74;
  color: black;
}

.content ul {
  margin-top: 10px;
  line-height: 2;
}

.content img {
  margin-bottom: 10px;
  margin-top: 10px;
}

.share-button-container {
  position: static;
  display: inline-block;
  /* width: 10%; */
}

/* .share-button-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  } */

.instaBackColor {
  background-image: linear-gradient(
    to left bottom,
    #f9ce34,
    #ffaf31,
    #ff903d,
    #ff704d,
    #ff5160,
    #f9396e,
    #ef1f7e,
    #e0008f,
    #cf00a0,
    #b800b3,
    #960ec5,
    #6228d7
  );
}

.share-btn {
  border: transparent;
  width: 30px;
  border-radius: 3px;
  background-color: #fff;
  cursor: default !important;
  height: fit-content;
}

.numb {
  /* font-family: Roboto Slab; */
  font-size: 50px;
  line-height: 1;
  color: #e5e5e5;
}

ol li::marker {
  font-size: 24px; 
  font-weight: bold;
}

.ArticleBox {
  box-shadow: rgba(0, 0, 0, 0.24) 4px 4px 7px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
}

.ArticleImg {
  /* max-width: 250px; */
  max-width: 160px;
  height: auto;
  border-radius: 20px;
  margin: 0px 20px 0px 0px;
}

.contentTableBox {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100% ;
  padding: 10px 0px 0px 10px;

  /* background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%); */
  background-color: #f1f3f4;
}

.contentTableBox ol li {
  list-style: none;
}

.hover-underline-animations {
  display: inline-block;
  position: relative;
  color: #000;
  margin-top: 3px;
}

.hover-underline-animations:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animations:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.backLine {
  font-size: 14px;
  font-weight: 600 !important;
}

.instabtn {
  margin-top: 3px;
}

blockquote {
  border-left: 5px solid #eee;
  font-size: 17.5px;
  margin: 0 0 20px;
  padding: 10px 20px;
}



.tdb_single_content
  .tdb-block-inner
  > *:not(.wp-block-quote):not(.alignwide):not(
    .alignfull.wp-block-cover.has-parallax
  ):not(.td-a-ad) {
  margin-left: auto;
  margin-right: auto;
}

.tagdiv-type .td_box_center {
  margin: 0 0 29px 0;
}

.tagdiv-type .td_quote_box {
  margin: 0;
  background-color: var(--td_container_transparent, #fcfcfc);
  border-left: 2px solid var(--td_theme_color, #4db2ec);
  padding: 15px 23px 16px 23px;
  position: relative;
  top: 6px;
  clear: both;
}

.tagdiv-type blockquote {
  padding: 0;
  position: relative;
  border-left: none;
  margin: 40px 5% 38px;
  font-style: italic;
  text-align: center;
}

.tdi_305,
.tdi_305 > p,
.tdi_305 .tdb-block-inner > p {
  font-family: var(--global-font-2) !important;
  font-size: 17px !important;
  line-height: 1.6 !important;
  font-weight: 400 !important;
}




@media (max-width: 767px) {


  .ArticleBox {

    display: flex;
    flex-direction: column;
  }
  .ArticleImg{

    width: 160px;
    max-width: fit-content;
}


  .contentTableBox {
    width: 100% !important;
    margin-top: 10px;
  }

  .mobiletop {
    margin-top: 30px !important;
  }
}