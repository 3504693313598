.podBtn{
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    background-color: #309b65;
    color: white;
    border: none;
  }


  .ImgBoxGuets2{


    height: auto;
    width: 100%
  }


  .cardImg{
    width: auto;
    text-align: center;
    height: 400px;
    border-radius: 15px;
  }
