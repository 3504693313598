.pagination-btn button{
    border: none;
    font-size: 18px;
    padding: 0px 8px 0px 8px;
}

.pagination-btn button:hover{
    background-color: #309b65;
    color: white;
}

.btn-pagination .isActives{
    background-color: #309b65;
}

.isActive{
    background-color: #309b65 !important;
    color: white !important;
}

.tabButton:hover{
    background-color: #309b65 !important;
    color: white !important;
}